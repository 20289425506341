import * as React from 'react';
import { navigate } from 'gatsby';

function Page404() {
  React.useEffect(() => {
    navigate('/');
  }, []);
  return <div>404</div>;
}

export default Page404;
